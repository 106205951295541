export function useNavLinks() {
  const { t } = useI18n({
    useScope: 'local',
  });
  const ownerSupport = {
    title: t('ownerSupport.title'),
    links: [
      { title: t('ownerSupport.links.registerAProduct'), url: '/register-product-intro' },
      { title: t('ownerSupport.links.bookAnAppointment'), url: '/book-appointment-intro' },
      { title: t('ownerSupport.links.productManuals'), url: '/search-manuals' },
      { title: t('ownerSupport.links.warrantyInformation'), url: '/warranty-info' },
      { title: t('ownerSupport.links.troubleshooting'), url: '' },
      { title: t('ownerSupport.links.contactCustomerSupport'), url: '' },
    ],
  };
  const helpCenter = {
    title: t('helpCenter.title'),
    links: [
      { title: t('helpCenter.links.chatSupport'), url: '/chat-support' },
      { title: t('helpCenter.links.faqs'), url: '/faqs' },
      { title: t('helpCenter.links.contactUs'), url: '/contact' },
    ],
  };
  // const smartHomes = {
  //   title: t('smartHomes.title'),
  //   links: pagesTest.value.map(item => {
  //     return {
  //       title: item.title,
  //       link: item.urlKey,
  //     };
  //   }),
  //   // links: [
  //   //   { title: t('smartHomes.links.carrier'), url: '/carrier-smart-home' }, // TODO: Add actual link
  //   //   { title: t('smartHomes.links.midea'), url: '/midea-smart-home' },
  //   // ],
  // };

  return { ownerSupport, helpCenter };
}
