<template>
  <div class="flex items-center w-full px-5">
    <div class="flex w-full items-center">
      <form method="get" :action="`/${locale}/search`" class="w-full">
        <TextInput
          id="search-dialog-input"
          ref="searchInput"
          class="outline-none text-primary-A900 font-bold"
          type="text"
          name="q"
          autocomplete="off"
          :placeholder="t('searchProducts')"
          :aria-label="t('searchProducts')"
          :is-optional="false"
          icon="search"
          rounded
          border
          @focus="show"
        >
          <template #after>
            <button
              v-if="values.q || isOpen"
              type="button"
              aria-label="clear search words "
              class="[ absolute right-0 z-10 top-unit-2 ] p-1 h-12 w-12 [ flex items-center justify-center ]"
              @click="clear"
            >
              <div class="bg-secondary-500 rounded-lg w-6 h-6 flex items-center justify-center">
                <svg-icon name="close" class="text-primary-A900" width="9" height="9" />
              </div>
            </button>
          </template>
          <template #icon>
            <svg-icon name="search" class="w-5 h-5 absolute top-4 left-3 z-10 text-secondary-400" />
          </template>
        </TextInput>

        <button type="submit" class="hidden" />
      </form>
    </div>

    <dialog ref="dialog" class="m-0" :open="isOpen">
      <SearchItems v-if="isOpen" :search-key="values.q" />
    </dialog>
  </div>
</template>
<script setup lang="ts">
import type { TextInput } from '#build/components';
import * as yup from 'yup';
const { t, locale } = useI18n({
  useScope: 'local',
});

const dialog = ref<HTMLDialogElement | null>(null);
const searchInput = ref<typeof TextInput | null>(null);

const isOpen = ref(false);

const { values, setFieldValue } = useForm({
  validationSchema: {
    q: yup.string().default(''),
  },
});

const show = () => {
  searchInput.value?.forceFocus();

  isOpen.value = true;
};

const close = () => {
  isOpen.value = false;
};

const route = useRoute();
watch(
  () => route.fullPath,
  () => {
    if (dialog.value?.open) {
      close();
    }
  },
);

const clear = () => {
  setFieldValue('q', '');
  close();
};
</script>

<style lang="postcss" scoped>
input::placeholder {
  @apply text-primary-700;
}

.common-search-list a {
  @apply flex justify-between items-center;
}

.search-result li:not(:last-child) {
  @apply border-b border-primary-300;
}
.search-suggestions__list {
  @apply flex items-center flex-wrap gap-3;
}

::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

dialog[open] {
  opacity: 1;
  top: 140px;

  background: white;
  width: 100%;
  height: calc(100vh - 140px);
}
</style>
<i18n>
{
  "en": {
    "searchProducts": "Search products ..."
  },
  "ar": {
    "searchProducts": "البحث عن المنتجات"
   }
}
</i18n>
