export function useGenerateToken() {
  const generateToken = async (msidn: string) => {
    try {
      const data = await makeHttpSimpleJSONRequest(
        'post',
        '/api/jwt-generator',
        {
          msidn,
        },
        {},
      );

      if (!data) {
        throw new Error('Failed to generate token');
      }

      return data.token;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    generateToken,
  };
}
