<template>
  <div v-if="isFetching" class="mt-5 px-5" />

  <template v-else>
    <section v-if="productsResult && productsResult.length" class="px-5 pt-5 pb-5">
      <div>
        <ul v-if="productsResult && productsResult.length" class="search-result text-primary-A900">
          <li v-for="(item, index) of productsResult" :key="index" class="flex mt-3 pb-5">
            <AppLink :to="`/${item.slug}`" class="flex items-center">
              <div class="[ w-24 h-24 ] [ bg-white ]">
                <AppImage
                  v-if="item.thumb"
                  :src="item.thumb.src"
                  class="object-contain w-full h-full"
                  :alt="item.thumb.alt"
                  width="96"
                  height="96"
                />
              </div>
              <div class="flex-1 flex flex-col items-start ml-5">
                <p class="line-clamp-2 max-w-56 text-left">
                  {{ item.name }}
                </p>

                <Money :value="item.price" class="text-primary-700 font-semibold" />
              </div>
            </AppLink>
          </li>
        </ul>
      </div>
    </section>

    <section v-if="noResults" class="px-6 mt-10">
      <svg-icon-not-found-search width="40" height="40" class="fill-current text-brown-600 mx-auto" />
      <h2 class="mt-10 text-primary-700 font-semibold text-xl text-center font-body mb-3">
        {{ $t('noSearchFor', { string: confirmedSearchWord }) }}
      </h2>
      <p v-purify="$t('enhanceNote')" class="flex-1 text-sm text-black" />
    </section>
  </template>
</template>

<script lang="ts" setup>
const { t: $t } = useI18n({
  useScope: 'local',
});

const props = defineProps<{
  searchKey: string;
}>();

const {
  searchBy,
  result: productsResult,
  isFetching,
  categoriesResult,
  confirmedSearchWord,
} = useSearchProduct(props.searchKey, {
  passive: false,
});

watch(
  () => props.searchKey,
  value => {
    searchBy.value = value;
  },
);

const noResults = computed(() => {
  return searchBy.value && noProducts.value && noCategories.value;
});

const noProducts = computed(() => {
  return !isFetching.value && !productsResult.value?.length;
});

const noCategories = computed(() => {
  return !isFetching.value && !categoriesResult.value?.length;
});
</script>

<i18n>
{
  "en": {
    "suggestedProducts": "Search Suggestions",
    "suggestedCategories": "Categories",
    "suggestedBrands": "Brands",
    "noSearchFor": "No Search Results for “{string}” ",
    "noSearchDescription": "Double-check your spelling, use more generic terms, try different keywords & you can refine your search later",
    "enhanceResults": "To Enhance your search",
    "enhanceNote": "To enhance the accuracy of your search query, it is recommended to verify the spelling of all included words and to experiment with various keywords"
  },
  "ar": {
    "suggestedProducts": "اقتراحات البحث",
    "suggestedCategories": "الاقسام",
    "suggestedBrands": "الماركات",
    "noSearchFor": "لا توجد نتائج ل “{string}” ",
    "noSearchDescription": "تحقق جيدًا من الإملاء ، واستخدم مصطلحات عامة ، وجرب كلمات رئيسية مختلفة ، ويمكنك تحسين البحث لاحقًا",
    "enhanceResults": "لتحسين البحث",
    "enhanceNote": "تأكد من الكلمات المستخدمة في البحث أو استخدم عبارات أخرى أو حاول بحث بعبارات أخرى للحصول على المحتوى المطلوب"
  }
}
</i18n>
