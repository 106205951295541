declare global {
  interface Window {
    infopidAuth: (token: string) => void;
    infopidSignout: () => void;
  }
}

export const useInfopid = () => {
  const { user } = useAuth();
  const { generateToken } = useGenerateToken();

  const startInfopid = async () => {
    try {
      if (import.meta.server) {
        return;
      }
      const phone = user.value?.phoneNumber;
      const token = await generateToken(phone);
      window.infopidAuth(token);
    } catch (e) {
      console.log((e as Error).message);
    }
  };

  const clearInfopid = () => {
    window.infopidSignout();
  };

  return {
    startInfopid,
    clearInfopid,
  };
};
