<template>
  <div class="sticky bottom-12 start-full z-50 flex items-center justify-end px-5 pb-5">
    <AppButton
      class="bg-[#128c7e] text-white p-5 shadow-lg flex items-center justify-center w-12 h-12 rounded-full"
      as="AppLink"
      :external="true"
      variant="floating"
      to="https://wa.me/+20219111"
    >
      <svg-icon name="contact/whatsapp" width="24" height="24" class="h-6 w-6" />
    </AppButton>
  </div>
</template>
