<template>
  <div
    id="search-bar"
    data-cy-region="search-bar"
    class="relative flex items-center border-primary-700"
    :class="{
      'w-75': !show,
      'w-100': show,
    }"
  >
    <form method="get" :action="`/${locale}/search`" class="search-form w-full z-[2]">
      <TextInput
        id="search-input"
        class="outline-none text-sm h-11 text-primary-A900"
        type="text"
        name="q"
        autocomplete="off"
        :placeholder="t('searchProducts')"
        :aria-label="t('searchProducts')"
        :rounded="true"
        :underline="true"
        :border="true"
        :is-optional="false"
        light
        @focus="() => (isFocusing = true)"
        @input="
          (e: string) => {
            search = e;
            touched = true;
          }
        "
      >
        <template #after>
          <button
            v-if="show"
            type="button"
            aria-label="clear search words "
            class="[ absolute right-0 z-10 top-unit-2 ] p-1 h-12 w-12 [ flex items-center justify-center ]"
            @click="
              () => {
                setFieldValue('q', '');
                forceTriggerInputBlur();
              }
            "
          >
            <div class="bg-secondary-500 rounded-lg w-7 h-7 flex items-center justify-center">
              <svg-icon name="close" class="stroke-[2] text-primary-A900" width="9" height="9" />
            </div>
          </button>
        </template>
        <template #hint>
          <div />
        </template>

        <template #icon>
          <svg-icon
            name="search"
            class="absolute h-5 w-5 top-1/2 transform -translate-y-1/2 left-3 z-10"
            :class="{
              'text-primary-A900 stroke-[2]': show,
              'text-secondary-700': !show,
            }"
          />
        </template>
      </TextInput>
    </form>
    <transition name="fade">
      <div
        v-if="show"
        class="searchResult__container shadow-custom-2 absolute bg-white rounded-3xl w-full left-0 top-0 z-1 overflow-y-auto pt-22"
      >
        <div class="px-5 max-h-[50vh] overflow-auto py-5">
          <Spinner v-if="isFetching" class="text-primary-A900 w-10 h-10 mx-auto" />
          <p v-else-if="noResults" class="text-center text-primary-A900">
            {{ t('noResults') }}
          </p>
          <div v-else>
            <div v-if="categoriesResult && categoriesResult.length">
              <h2 class="text-black text-left font-body text-lg font-bold">
                {{ t('suggestedCategories') }}
              </h2>

              <ul
                v-if="categoriesResult && categoriesResult.length"
                data-attr="searchResult__list"
                class="my-3 flex flex-wrap gap-4 text-black font-body font-medium text-base"
              >
                <li v-for="(category, index) of categoriesResult" :key="index" class="flex flex-col text-center">
                  <AppLink
                    :to="`/${category.url_path}`"
                    class="w-full flex flex-col items-center justify-between rounded-6sm px-3 py-2 uppercase"
                    :class="
                      gradientClasses[
                        ((index % gradientClasses.length) + gradientClasses.length) % gradientClasses.length
                      ]
                    "
                  >
                    <span class="flex-1 flex flex-col">
                      <p data-attr="searchResult__item__name" class="font-normal font-body uppercase">
                        {{ category.name }}
                      </p>
                    </span>
                  </AppLink>
                </li>
              </ul>
            </div>
            <div v-if="productsResult && productsResult.length">
              <h2 class="text-primary-A900 text-left text-lg font-bold">
                {{ t('suggestedProducts') }}
              </h2>

              <ul
                v-if="productsResult && productsResult.length"
                data-attr="searchResult__list"
                class="search-result mt-4 text-primary-700"
              >
                <li v-for="(item, index) of productsResult" :key="index">
                  <AppLink :to="`/${item.slug}`" class="flex w-full">
                    <AppImage
                      v-if="item.thumb"
                      data-attr="searchResult__item__thumb"
                      class="w-16 rounded-md h-16 object-contain"
                      :src="item.thumb.src"
                      :alt="item.thumb.alt"
                      width="64"
                      height="64"
                    />
                    <div class="flex-1 flex flex-col justify-center">
                      <p data-attr="searchResult__item__name" class="text-primary-A900">
                        {{ item.name }}
                      </p>

                      <Money :value="item.price" class="text-primary-700 font-semibold" />
                    </div>
                  </AppLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { object, string } from 'yup';

const gradientClasses = ['gradient-primary-800', 'gradient-primary-700', 'gradient-primary-600'];

const { t, locale } = useI18n({
  useScope: 'local',
});

/**
 * A Feature Flag to determine to trigger the search upon hovering or not
 */
const IS_PASSIVE = true;

const route = useRoute();
const touched = ref(false);
const isFocusing = ref(false);

const { setFieldValue } = useForm({
  validationSchema: toTypedSchema(
    object({
      q: string(),
    }),
  ),
});

const { value, setValue } = useField<string>('q');

const {
  searchBy: search,
  result: productsResult,
  categoriesResult,
  execute,
  isFetching,
} = useSearchProduct(route?.query?.q?.toString() || '', {
  passive: true,
});

watch(value, newValue => {
  if (newValue) {
    search.value = newValue;
  }
});
const show = computed<boolean>(() => {
  if (IS_PASSIVE && isFocusing.value) {
    execute();
    fetchBrands();
    return true;
  }

  if (search.value && touched.value) {
    return true;
  }

  return isFocusing.value;
});

onMounted(() => {
  document.addEventListener('click', e => {
    if (e.target instanceof Element && document.getElementById('search-bar')?.contains(e.target)) return;
    isFocusing.value = false;
  });

  setValue(route?.query?.q?.toString() || '');
});

// clear search to force close menu when navigating
watch(route, () => {
  search.value = '';
});

const noResults = computed(() => !productsResult.value?.length && !categoriesResult.value?.length && !isFetching.value);

function forceTriggerInputBlur() {
  isFocusing.value = false;
}
</script>

<style lang="postcss" scoped>
input::placeholder {
  @apply text-primary-700;
}

.search-result li {
  @apply pb-5 flex;
}
.search-result img {
  @apply flex w-16 h-16 mr-4;
}
.search-result span {
  @apply flex  items-start;
}

.search-result {
  & li {
    @apply pt-4;
  }
  & li:not(:last-child) {
    @apply pb-4 border-b border-gray-100;
  }
}

.searchResult__container {
  top: -20px;
  left: -20px;
  width: calc(100% + 40px);
}
</style>
<i18n>
{
  "en": {
    "searchProducts": "Search products .... ",
    "suggestedProducts": "Suggested Products",
    "suggestedCategories": "Categories",
    "suggestedBrands": "Brands",
    "noResults": "No results found"
  },
  "ar": {
    "searchProducts": "البحث عن المنتجات",
    "suggestedProducts": "منتجات مقترحة",
    "suggestedCategories": "الاقسام",
    "suggestedBrands": "الماركات",
    "noResults": "لا توجد نتائج"
  }
}

</i18n>
