<template>
  <div class="ProfileMenu ml-auto hidden lg:inline-block" data-cy="profile-menu">
    <button ref="buttonRef" class="lg:ml-auto hidden lg:flex items-center justify-start" @click="toggle">
      <svg-icon name="user" class="text-primary-700" width="25" height="25" />
      <span class="max-w-30 truncate ml-3 text-sm hidden text-primary-700 lg:block text-start">
        {{ `${user?.firstname} ${user?.lastname}` }}
      </span>
    </button>

    <transition name="fade">
      <template v-if="isOpen">
        <Card class="ProfileMenu__list flex flex-col px-3 pt-3" notch-position="top" notch-size="small">
          <span class="text-primary-700 [ flex items-start justify-start ]">
            <svg-icon name="user" width="30" height="30" class="mr-2 text-primary-700" />
            {{ user && user.firstname }}
          </span>

          <AppLink
            to="/account"
            class="ProfileMenu__item px-2 mt-5 pb-5 border-b border-secondary-300"
            data-cy="user-account"
          >
            <svg-icon name="user" width="21" height="21" class="mr-2 text-primary-700" />
            <span>{{ $t('accountOverview') }}</span>
          </AppLink>

          <AppLink
            to="/account/edit"
            class="ProfileMenu__item px-2 mt-5 pb-5 border-b border-secondary-300"
            data-cy="user-account"
          >
            <svg-icon name="settings" width="21" height="21" class="mr-2" />
            <span>{{ $t('accountSetting') }}</span>
          </AppLink>

          <button
            type="button"
            :title="$t('logout')"
            class="ProfileMenu__item py-5 px-2"
            data-cy="logout"
            @click="loggingOut"
          >
            <svg-icon name="account/logout" class="w-5 h-5 mr-3 inline" />
            <span class="text-red-700">{{ $t('logout') }}</span>
          </button>
        </Card>
      </template>
    </transition>
  </div>
</template>

<script setup lang="ts">
const { isOpen, toggle } = useDropdown();
const { user } = useAuth();
const route = useRoute();
const { logout } = useLogout();
const { success } = useAlerts();
const { t: $t } = useI18n({
  useScope: 'local',
});

// close menu when navigating
watch(
  () => route.name,
  () => {
    toggle(false);
  },
);

/**
 * Used to logout and go back to the home page
 */
function loggingOut() {
  logout();
  toggle(false);
  success($t('loggedOut').toString());
}

const buttonRef = ref<HTMLButtonElement | null>(null);

// set css variables for the dropdown
watch(isOpen, value => {
  if (value) {
    const width = (buttonRef.value?.offsetWidth ?? 0) + 100;
    document.documentElement.style.setProperty('--list-width', `${width}px`);
  }
});
</script>

<style lang="postcss" scoped>
:root {
  --list-width: 220px;
}
.ProfileMenu {
  @apply relative;
  &__list {
    @apply absolute -top-2 -left-12 flex flex-col z-20;
    width: var(--list-width);
    min-height: 240px;
  }

  &__item {
    @apply flex items-center w-full  text-primary-700 focus:outline-none;
  }
}
</style>

<i18n>
{
  "en": {
    "accountOverview": "My Profile",
    "myOrders": "My Orders",
    "myInstallments": "My Installments",
    "myWishlist": "My Wishlist",
    "logout":  "Logout",
    "loggedOut": "You are now logged out",
    "toggleProfile": "Toggle profile dropdown",
    "accessSellerCenter": "Seller Center",
    "accountSetting": "Account Settings"
  },
  "ar": {
    "accountOverview": "حسابي",
    "myOrders": "طلباتي",
    "myInstallments": "أقساطي",
    "myWishlist": "قائمة رغباتي",
    "logout":  "تسجيل الخروج",
    "loggedOut": "تم تسجيل الخروج",
    "toggleProfile": "تبديل القائمة المنسدلة للملف الشخصي",
    "accessSellerCenter": "مركز إدارة المتجر",
    "accountSetting": "إعدادات الحساب"
  }
}
</i18n>
