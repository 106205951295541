<template>
  <footer data-cy-region="footer" class="bg-primary-700 text-primary-100 antialiased text-sm">
    <div class="FooterLinks px-5 md:px-0 container mx-auto grid gird-cols-1 gap-y-12">
      <div v-for="(col, i) of cols" :key="'footer-nav-col-' + i">
        <h3 class="text-xl font-bold">{{ col.title }}</h3>
        <ul class="mt-4 lg:mt-5 grid grid-cols-2 lg:grid-cols-1 gap-y-4">
          <li v-for="(link, j) of col.links" :key="'footer-nav-col-link-' + i + '-' + j">
            <AppLink v-if="link.url" :to="link.url">{{ link.title }}</AppLink>
            <span v-else>{{ link.title }}</span>
          </li>
        </ul>
      </div>

      <div class="lg:hidden">
        <h3 class="text-xl">{{ $t('downloadOurApp') }}</h3>
        <ul class="mt-4 lg:mt-5 grid grid-cols-2 gap-y-4">
          <li class="flex items-center">
            <svg-icon name="apple" height="18" width="16" class="mr-2" />

            <AppLink to="/">{{ $t('downloadAppStore') }}</AppLink>
          </li>
          <li class="flex items-center">
            <svg-icon name="play" height="18" width="15" class="mr-2" />

            <AppLink to="/">{{ $t('downloadGooglePlay') }}</AppLink>
          </li>
        </ul>
      </div>

      <div>
        <h3 class="text-xl font-bold">{{ $t('newsletter') }}</h3>
        <p class="mt-4 lg:mt-5">{{ $t('subscribe') }}</p>
        <form class="mt-4" @submit="onSubmit">
          <TextInput
            id="newsletter_email"
            name="email"
            class="w-full relative"
            type="email"
            :placeholder="$t('newsletterEmail')"
            aria-label="Email Address"
            icon="mail"
            inverted
          >
            <template #after>
              <button
                type="submit"
                aria-label="Send Email"
                class="SubmitButton [ absolute top-px right-px ] [ flex items-center justify-center h-11 w-11 md:h-13 md:w-13 ]"
                :disabled="!meta.valid"
              >
                <svg-icon
                  name="send-message-button"
                  class="shadow-custom-2 h-6 w-6 flex-shrink-0"
                  :class="{ 'transform -scale-x-1': $i18n.locale === 'ar' }"
                />
              </button>
            </template>
            <template #icon>
              <svg-icon
                name="mail"
                class="w-6 h-6 text-white shadow-custom-2 absolute top-[2px] transform translate-y-1/2 left-3"
              />
            </template>
          </TextInput>
        </form>
      </div>
    </div>

    <div
      class="px-5 md:px-0 grid FooterLinks mt-12 lg:mt-13 container mx-auto lg:border-b border-primary-600 gap-x-16 xl:px-0"
    >
      <div class="flex flex-col items-start justify-center lg:justify-start order-2 lg:order-1 col-span-2 gap-y-2">
        <div class="mt-0 inline-flex items-center gap-x-4">
          <p class="w-12">{{ $t('carrier') }}</p>
          <a href="https://www.facebook.com/CarrierEgypt?mibextid=ZbWKwL" aria-label="Like us on Facebook">
            <svg-icon name="facebook" class="w-5 h-4" />
          </a>
          <a
            href="https://www.instagram.com/carrieregyptofficial?igsh=MWM2Z3Y5bGF1YW8yaA=="
            aria-label="Follow us on Instagram"
          >
            <svg-icon name="instagram" class="w-5 h-5" />
          </a>
          <a href="https://youtube.com/@carrieregyptofficial582?si=CEW1oXzWF5TWbXWt" aria-label="Like us on Youtube">
            <svg-icon name="youtube" class="w-6 h-5" />
          </a>
        </div>
        <div class="mt-0 inline-flex items-center gap-x-4">
          <p class="w-12">{{ $t('midea') }}</p>
          <a href="https://www.facebook.com/MideaEgypt?mibextid=ZbWKwL" aria-label="Like us on Facebook">
            <svg-icon name="facebook" class="w-5 h-4" />
          </a>
          <a
            href="https://www.instagram.com/mideaegyptofficial?igsh=aTZzdWhpNmF2eGw0"
            aria-label="Follow us on Instagram"
          >
            <svg-icon name="instagram" class="w-5 h-5" />
          </a>
          <a href="https://youtube.com/@mideaegyptofficial647?si=5oPvC83zT_1RchSI" aria-label="Like us on Youtube">
            <svg-icon name="youtube" class="w-6 h-5" />
          </a>
        </div>
      </div>

      <div class="mb-9 lg:my-0 flex gap-x-5 items-center justify-center lg:justify-start order-1 lg:order-2 col-span-2">
        <svg-icon name="visa-inverted" class="w-12 h-12" />
        <svg-icon name="master_card" class="w-10 h-10" />
      </div>

      <div class="hidden lg:flex flex-col lg:flex-row lg:items-center lg:order-3">
        <div class="mt-3 lg:mt-0 w-full flex items-center justify-between">
          <a href="#" :aria-label="$t('downloadAppStore')" class="flex items-center mr-4">
            <svg-icon name="apple" height="18" width="16" class="mr-2" />
            {{ $t('downloadAppStore') }}
          </a>
          <a href="#" :aria-label="$t('downloadGooglePlay')" class="flex items-center">
            <svg-icon name="play" height="18" width="15" class="mr-2" />
            {{ $t('downloadGooglePlay') }}
          </a>
        </div>
      </div>
    </div>

    <p class="bg-primary-A1000 [ mt-8 lg:mt-13 h-12 ] [ flex items-center justify-center ]">
      ©{{ new Date().getFullYear() }} - {{ $t('brand') }} | {{ $t('copyrights') }}
    </p>
  </footer>
</template>

<script setup lang="ts">
import * as yup from 'yup';

defineComponent({
  name: 'TheFooterMainSection',
});

const { t: $t } = useI18n({ useScope: 'local' });

const { submitForm } = useSubscribeToNewsletter();
const { success, error } = useAlerts();
const { handleSubmit, meta } = useForm({
  validationSchema: toTypedSchema(
    yup.object({
      email: yup.string().email(),
    }),
  ),
});

const onSubmit = handleSubmit(async ({ email }) => {
  try {
    if (!email) {
      error($t('error'), $t('mustBeEmail'));
      return;
    }

    await submitForm(email);
    success($t('subscribed'));
  } catch (e) {
    console.log(e);
    error($t('error', (e as Error)?.message.replace('[GraphQL]', '')));
  }
});

const cols = [
  {
    title: $t('ownerSupport'),
    links: [
      { title: $t('registerAProduct'), url: '/register-product-intro' },
      { title: $t('bookAnAppointment'), url: '/book-appointment-intro' },
      { title: $t('productManuals'), url: '/search-manuals' },
      { title: $t('warrantyInformation'), url: '/warranty-info' },
      { title: $t('troubleshooting'), url: '' },
      { title: $t('contactCustomerInfo'), url: '' },
    ],
  },
  {
    title: $t('helpCenter'),
    links: [
      { title: $t('chatSupport'), url: '/chat-support' },
      { title: $t('contactUs'), url: '/contact' },
      { title: $t('faqs'), url: '/faqs' },
    ],
  },
  {
    title: $t('miracoEgypt'),
    links: [
      {
        title: $t('aboutUs'),
        url: '/about-us',
      },
      {
        title: $t('miracoStores'),
        url: '/stores',
      },
      {
        title: $t('careers'),
        url: '/careers',
      },
    ],
  },
  {
    title: $t('more'),
    links: [
      {
        title: $t('refunds'),
        url: '/returns-and-refunds',
      },
      {
        title: $t('privacyPolicy'),
        url: '/privacy-policy',
      },
      {
        title: $t('termsAndConditions'),
        url: '/terms-and-conditions',
      },
    ],
  },
];
</script>

<style lang="postcss" scoped>
footer {
  @screen md {
    height: 100%;
  }

  .FooterLinks {
    margin-top: 48px;
    @screen md {
      grid-template-columns: 1fr 1fr 1fr 1fr 370px;
    }

    a {
      @apply relative;

      &::before {
        content: '';
        width: calc(100% + 10px);
        transform: scaleX(0);
        @apply absolute bottom-0 right-0 left-0 text-secondary-700;
        box-shadow: 0px 2px 0px 5px currentColor;
        transition: transform 0.3s ease-in-out;
      }

      /* &:hover {
        &::before {
          transform: scaleX(1);
        }
      } */
    }

    & > div:not(:last-child) {
      @apply relative;
    }
    & > div:not(:last-child)::after {
      @apply mx-auto;
      @screen md {
        content: unset;
      }
      height: 1px;
      width: calc(100% - 50px);
      background-color: #d0dedc;
      position: absolute;
      bottom: -24px;
      right: 0;
      left: 0;
    }
  }
}

.SubmitButton {
  background: linear-gradient(225deg, #1363c9 0%, #1b2c70 100%);
  border-radius: 20px;
  box-shadow: 0px 10px 48px -12px rgba(24, 145, 246, 0.15);
}
</style>

<i18n>
{
  "en": {
    "customerService": "Customer Service",
    "faqs": "FAQs",
    "contactUs": "Contact us",
    "about": "About",
    "who": "Who are we?",
    "stores": "Stores",
    "refunds": "Refund & Return",
    "termsAndConditions": "Terms & Conditions",
    "subscribe": "Subscribe to our newsletter",
    "follow": "Follow us",
    "copyrights": "All right reserved",
    "ownerSupport": "Owner Support",
    "registerAProduct": "Register a Product",
    "warrantyInformation": "Warranty Information",
    "bookAnAppointment": "Book an Appointment",
    "downloadOurApp": "Download our App",
    "downloadAppStore": "Download App Store",
    "downloadGooglePlay": "Download Google Play",
    "newsletterEmail": "Add your email",
    "send": "Send Email",
    "helpCenter": "Help Center",
    "chatSupport": "Chat Support",
    "miracoEgypt": "About Miraco",
    "miracoStores": "Branches & Dealers",
    "aboutUs": "About Us",
    "refundsNReturns": "Returns and Refund",
    "termsNConditions": "Terms and Conditions",
    "privacyPolicy": "Privacy Policy",
    "newsletter": "Newsletter",
    "more": "More",
    "productManuals": "Product Manuals",
    "brand": "Miraco Egypt",
    "subscribed": "Subscribed Successfully",
    "error": "Error :",
    "mustBeEmail": "Must be valid Email",
    "careers": "Careers",
    "smartHome": "Smart Home",
    "subscribedToNewsletter": "Subscribed to newsletter successfully!",
    "troubleshooting": "Troubleshooting",
    "contactCustomerInfo": "Contact Customer Support",
    "midea": "Midea",
    "carrier": "Carrier",
  },
  "ar": {
    "customerService": "خدمة العملاء",
    "faqs": "الأسئلة الشائعة",
    "contactUs": "تواصل معنا",
    "about": "عن الشركة",
    "who": "من نحن؟",
    "stores": "الفروع",
    "more": "المزيد",
    "newsletter": "النشرة",
    "refunds": "الاسترجاع",
    "termsAndConditions": "سياسة الاستخدام",
    "subscribe": "سجل للنشرة",
    "follow": "تابعنا",
    "copyrights": "جميع الحقوق محفوظة",
    "ownerSupport": "الدعم الخاص بالمُلاك",
    "registerAProduct": "تسجيل منتج",
    "warrantyInformation": "معلومات الضمان",
    "aboutUs": "عنّا",
    "refundsNReturns": "الاسترجاع والاستبدال",
    "helpCenter": "الدعم الفني",
    "chatSupport": "الدعم عبر الشات",
    "miracoEgypt": "ميراكو",
    "bookAnAppointment": "احجز موعد",
    "privacyPolicy": "سياسة الخصوصية",
    "downloadOurApp": "حمل تطبيقنا",
    "downloadAppStore": "التحميل من آب ستور",
    "downloadGooglePlay": "التحميل من جوجل بلاي",
    "newsletterEmail": "أدخل بريدك الإلكتروني",
    "productManuals": "دليل استخدام المنتج",
    "brand": "ميراكو مصر",
    "subscribed": "تم التسجيل بنجاح",
    "error": "خطأ: ",
    "mustBeEmail": "ﻻبد من ادخال ايميل الكتروني صحيح",
    "careers": "الوظائف",
    "smartHome": "المنزل الذكي",
    "subscribedToNewsletter": "تم الاشتراك في النشرة بنجاح!",
    "troubleshooting": "حل المشاكل",
    "contactCustomerInfo": "تواصل مع خدمة العملاء",
    "miracoStores": "الفروع و الموزعون",
  }
}
</i18n>
