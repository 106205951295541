<template>
  <div id="category-menu" data-cy-region="category-menu" class="w-full hidden lg:block relative">
    <ul class="container mx-auto CategoryList flex items-center gap-x-5 text-sm text-primary-700">
      <li class="flex items-center justify-start cursor-pointer" @mouseover="selectedItem = { type: 'type' }">
        <button
          class="flex items-center justify-start cursor-pointer"
          @mouseover="selectedItem = { type: 'type' }"
          @click="selectedItem = { type: 'type' }"
        >
          <span class="text-primary-700 font-display font-medium">{{ $t('shopBy', { name: $t('category') }) }}</span>
          <svg-icon name="chevron-down" class="w-2 h-2 ml-2" />
        </button>
      </li>

      <li class="flex items-center justify-start cursor-pointer" @mouseover="selectedItem = { type: 'hp' }">
        <button
          class="flex items-center justify-start cursor-pointer"
          @mouseover="selectedItem = { type: 'hp' }"
          @click="selectedItem = { type: 'hp' }"
        >
          <span class="text-primary-700 font-display font-medium">{{ $t('shopBy', { name: $t('capacity') }) }}</span>
          <svg-icon name="chevron-down" class="w-2 h-2 ml-2" />
        </button>
      </li>

      <li class="text-red-700 font-display font-medium flex items-center">
        <AppLink to="/deals" class="flex items-center justify-center exclude-ellipse">
          <svg-icon name="offer" width="28" height="30" class="mr-2" />
          {{ $t('deals') }}</AppLink
        >
      </li>
      <li class="text-black ml-auto">{{ $t('shopByBrand') }}</li>
      <ul class="flex items-center divide-x-2 pb-3">
        <li
          v-for="(brand, index) in brands"
          :key="brand.id ?? index"
          class="text-red-700 font-display font-medium flex items-center px-2 BrandItem pt-2"
        >
          <AppLink :to="`/brands/${brand.url_key}`" class="flex items-center justify-center exclude-ellipse">
            <AppImage
              :src="brand?.image_url ?? ''"
              :alt="brand?.image_alt_value ?? ''"
              class="object-contain w-[80px] h-[32px] md:w-[80px] md:h-[32px]"
              width="80"
              height="32"
              fit="contain"
            />
          </AppLink>
        </li>
      </ul>
    </ul>

    <ClientOnly>
      <TransitionGroup name="fade" tag="dev">
        <div v-if="selectedItem?.type === 'type'" key="type" class="absolute left-0 right-0 top-full w-full z-10">
          <div class="w-full bg-white rounded-b-[100px] overflow-auto">
            <div class="category-menu container mx-auto CategoryMenu bg-white">
              <div class="CategoryMenu__categories">
                <div v-for="(child, index) in categories" :key="child.id ?? index" class="SubCategory">
                  <h3 class="antialiased tracking-wide font-bold text-lg text-primary-500">
                    <AppLink :to="`/${child.url_key}`">{{ child.name }}</AppLink>
                  </h3>
                </div>
              </div>
              <OfferCard v-if="offer" v-bind="offer" />
            </div>
          </div>
        </div>

        <div
          v-if="selectedItem?.type === 'hp' && acAttributes?.length"
          key="hp"
          class="absolute left-0 right-0 w-full z-10"
        >
          <div class="w-full bg-white rounded-b-[100px] overflow-auto">
            <div class="category-menu container mx-auto CategoryMenu bg-white">
              <div v-if="acAttributes?.length" class="CategoryMenu__categories">
                <div v-for="(option, idx) in acAttributes" :key="idx" class="SubCategory">
                  <h3 class="antialiased tracking-wide font-bold text-lg text-primary-500 font-body">
                    <NuxtLink
                      :to="{
                        path: `/${$i18n.locale}/products`,
                        query: {
                          'title-by': 'ac_horse_power',
                          ac_horse_power: option?.value,
                          extras: base64Encode({
                            fallBack: option?.label,
                          }),
                        },
                      }"
                      >{{ $t('k', { name: option?.label }) }}
                    </NuxtLink>
                  </h3>
                </div>
              </div>
              <OfferCard v-if="offer" v-bind="offer" />
            </div>
          </div>
        </div>
      </TransitionGroup>
    </ClientOnly>

    <Teleport v-if="selectedItem" to="#after-header-fade">
      <div
        key="mega-menu-backdrop"
        class="bg-black fixed inset-x-0 bottom-0 top-50 w-full h-full opacity-50 z-20"
        @mouseover="selectedItem = undefined"
      ></div>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { debounce } from 'lodash-es';
import { base64Encode } from '~/utils/base64';

const { t: $t } = useI18n({
  useScope: 'local',
});

let listener: ReturnType<typeof document.addEventListener> | undefined;

const route = useRoute();

const data = await useMegaMenuCategories();
const brandsData = await useBrands();
const { attributes } = await useAttributes();
const acAttributes = attributes?.value?.find(
  attribute => attribute?.attribute_code === 'ac_horse_power',
)?.attribute_options;

const brands = brandsData.brands.filter(brand => brand.is_shown_in_homepage === 1);

const categories = data?.categories;

const selectedItem = ref<{ type: 'hp' | 'type' } | undefined>(undefined);

watch(selectedItem, value => {
  if (!value && listener) {
    document.removeEventListener('mousemove', listener);
    return;
  }
  if (value)
    listener = document.addEventListener(
      'mouseover',
      debounce(e => {
        if (e.target instanceof Element && document.getElementById('category-menu')?.contains(e.target)) return;
        selectedItem.value = undefined;
      }, 200),
    );
});

watch(
  () => route.path,
  () => {
    selectedItem.value = undefined;
  },
  {
    deep: true,
  },
);

const offer = computed(() => {
  return (selectedItem.value as any)?.offer;
});
</script>

<style lang="postcss" scoped>
.CategoryList {
  a {
    @apply px-2;
  }

  .nuxt-link-active,
  a:hover,
  li span:hover {
    &:not(.exclude-ellipse) {
      @apply relative font-bold;
      &::after {
        @apply absolute;
        content: '';
        background-size: 100% 100%;
        height: 77px;
        width: calc(100% + 78px);
        bottom: -4px;
        right: -52px;
        top: -37px;
        z-index: -1;
      }
    }
  }
  .router-link-active span {
    @apply font-bold;
  }
}

h3 a:hover {
  @apply relative cursor-pointer;
  &::after {
    @apply absolute;
    content: '';
    background: linear-gradient(45deg, #d02e25 0%, #eb5951 100%);
    height: 4px;
    width: 86%;
    bottom: -10px;
    border-radius: 2px;
    left: 0;
    right: 0;
  }
}
.CategoryMenu {
  display: grid;
  grid-template-columns: 1fr 387px;
  padding-top: 40px;

  .CategoryMenu__categories {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 60px;
  }
}

.BrandItem {
  .router-link-active::after {
    content: '';
    @apply absolute w-[11px] h-[11px] rounded-full bg-primary-700 top-7 mt-4;
  }
}
</style>

<i18n>
{
  "en": {
    "deals": "Deals",
    "shopBy": "Shop By {name}",
    "type": "Type",
    "capacity": "Capacity",
    "k": "{name} HP",
    "category": "Category",
    "shopByBrand": "Shop By Brand"
  },
  "ar": {
    "deals": "العروض",
    "shopBy": "التصفية حسب {name}",
    "type": "النوع",
    "capacity": "السعة",
    "k": "{name} حصان",
    "category": "القسم",
    "shopByBrand": "تسوق بالماركات"
  }
}
</i18n>
