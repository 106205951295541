<template>
  <div
    id="app"
    class="bg-page-secondary min-h-screen"
    :class="{
      'header-hidden': !showHeader,
      'header-moving': currentScrollPosition > 0,
    }"
  >
    <TheHeader />
    <div id="after-header" />
    <div id="after-header-fade" transition="TransitionFade" />
    <div id="after-header-slide" transition="TransitionSlideUp" />

    <ClientOnly>
      <BottomSheet />
    </ClientOnly>

    <main id="main" class="z-auto relative bg-bg md:bg-page-secondary pb-10">
      <slot />
    </main>

    <AlertGroup />
    <FloatingButton v-if="whatsappToggle" />
    <TheFooter />
    <VitePwaManifest />
    <CompareMenuWrapper />
  </div>
</template>

<script setup lang="ts">
import { type Organization, type SearchAction, type WebSite } from 'schema-dts';
import { throttle } from 'lodash-es';
const { user } = useAuth();
const { startInfopid, clearInfopid } = useInfopid();
useSeoLinks();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// const { shouldShowComparison } = useComparison();
const {
  public: { appUrl: url },
} = useRuntimeConfig();
const showHeader = ref(true);
const currentScrollPosition = ref(0);
const lastScrollPosition = ref(0);
const scrollOffset = ref(100);
const whatsappToggle = ref(true);

const onScroll = throttle(() => {
  currentScrollPosition.value = window.pageYOffset;

  if (window.pageYOffset < 0) {
    return;
  }
  // if the header's distance covered still hasn't exceeded the scrolloffset -> return
  if (Math.abs(window.pageYOffset - lastScrollPosition.value) < scrollOffset.value) {
    return;
  }

  showHeader.value = window.pageYOffset < lastScrollPosition.value;
  lastScrollPosition.value = window.pageYOffset;
}, 300);

const mounted = ref();

onMounted(() => {
  mounted.value = true;
});

watchEffect(() => {
  if (process.server) {
    return;
  }
  if (mounted.value) {
    // set current scroll position in case page is loaded with scroll
    currentScrollPosition.value = window.pageYOffset;
    window.addEventListener('scroll', onScroll);
  }
});

watchEffect(() => {
  // browser only
  if (import.meta.server) {
    return;
  }

  if (user.value) {
    startInfopid();
    return;
  }

  clearInfopid();
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', onScroll);
});

// https://developers.google.com/search/docs/data-types/sitelinks-searchbox
const websiteWithSearchAction: WebSite = {
  '@type': 'WebSite',
  url,
  potentialAction: {
    '@type': 'SearchAction',
    target: `${url}/search?q={search_term_string}`,
    'query-input': 'required name=search_term_string',
  } as SearchAction,
};

const org: Organization = {
  '@id': url,
  '@type': 'Organization',
  // TODO: Organization name
  name: 'Unique',
  url,
  logo: `${url}/img/logo.png`,
  sameAs: [
    // TODO: Any social links to facebook/twitter/whatever
  ],
};

useJsonld({
  '@context': 'https://schema.org',
  '@graph': [websiteWithSearchAction, org],
});

onMounted(() => {
  useHead({
    style: [
      {
        innerHTML: `
        :root {
          --scroll-width: ${window.innerWidth - document.documentElement.clientWidth}px
        }
    `,
      },
    ],
  });
});
</script>

<style lang="postcss" scoped>
#app {
  @apply h-full w-full grid;
  grid-template-areas:
    'header'
    'content'
    'footer';

  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
}

#main {
  grid-area: content;
}

:deep(.main-navigation) {
  transform: translateY(0);
  transition:
    transform 300ms linear,
    top ease-out 0.2s;
}

.header-moving {
  :deep(.page-header) {
    @apply bg-primary-300;
  }
}
.header-hidden {
  :deep(.main-navigation) {
    transform: translateY(-100%);
  }
}

:deep(.full-view) {
  display: flex;
  align-items: end;
}
</style>

<style>
body:has(dialog[open]) {
  overflow: hidden;
}
</style>

<i18n>
{
  "en": {
    "cancel": "Cancel"
  },
  "ar": {
    "cancel": "الغاء"
  }
}
</i18n>
