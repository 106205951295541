<template>
  <header
    data-cy-region="header"
    class="shadow-custom-2 md:shadow-none bg-white md:bg-page-secondary rounded-b-3xs md:rounded-none relative z-30 lg:pb-7"
  >
    <div class="hidden lg:flex items-center bg-primary-700 px-2 h-12 text-white">
      <div class="container mx-auto flex justify-between">
        <div class="flex space-x-10">
          <button type="button" class="flex items-center" @click="showShoppingGuide = true">
            {{ $t('shoppingGuide') }}
          </button>

          <NavDropdown id="header-owner-support" :title="ownerSupport.title" :links="ownerSupport.links"></NavDropdown>
          <NavDropdown
            v-if="isDevelopment"
            id="header-smart-homes"
            :title="smartHomes.title"
            :links="smartHomes.links"
          ></NavDropdown>
          <AppLink to="/about-us" class="flex items-center">
            {{ $t('aboutUs') }}
          </AppLink>
        </div>

        <div class="flex items-center space-x-10">
          <a href="tel:19111" class="flex items-center">
            <svg-icon name="phone" class="h-4 w-6 text-white mb-0" /><span>19111</span>
          </a>

          <NavDropdown id="help-center-support" :title="helpCenter.title" :links="helpCenter.links"></NavDropdown>
          <a class="ml-3" :href="switchLocalePath($i18n.locale === 'en' ? 'ar' : 'en')">{{ $t('switchLang') }}</a>
        </div>
      </div>
    </div>

    <div class="container mx-auto pt-8 px-5 lg:px-0 flex justify-between items-center">
      <div class="flex-1 flex items-center md:gap-x-10">
        <button type="button" class="lg:hidden" title="Open Menu" @click="openSideMenu">
          <svg-icon name="menu" class="h-5 w-8 md:w-8 md:h-7" />
        </button>

        <AppLink to="/" class="mx-auto md:mx-0" aria-label="logo">
          <svg-icon name="logo" class="h-[21px] w-[122px] md:w-41 md:h-7" alt="Miraco Logo" />
        </AppLink>

        <QuickSearch class="mt-6 lg:mt-0 flex-1 md:max-w-[500px] [ hidden lg:flex ]" />
      </div>

      <div class="relative flex items-center gap-x-5 justify-between">
        <client-only>
          <AppLink v-if="!isLoggedIn" to="/login" class="hidden lg:flex lg:ml-auto items-center">
            <svg-icon name="user" class="h-6 w-6 text-primary-700" />
            <span class="ml-3 text-sm text-primary-700 hidden lg:block">{{ $t('loginOrRegister') }}</span>
          </AppLink>
          <ProfileDropdown v-else />
        </client-only>
        <client-only>
          <AppLink
            :to="isLoggedIn ? '/account/wishlist' : '/login'"
            :aria-label="$t('toFavorite')"
            class="lg:ml-auto hidden lg:flex items-center"
          >
            <svg-icon name="favorite" width="30" height="26" />
          </AppLink>
        </client-only>

        <AppLink to="/cart" aria-label="Cart" class="ml-auto relative flex items-center justify-center cursor-pointer">
          <svg-icon name="cart" class="w-8 md:w-8 aspect-square" />
          <span
            v-show="count"
            class="bg-coloration-error [ absolute -top-2 -right-2 ] [ h-6 w-6 rounded-lg ] [ text-white text-xs ] [ flex items-center justify-center ]"
          >
            {{ count }}
          </span>
        </AppLink>
      </div>
    </div>
    <SearchDialog class="header-search mt-6 lg:mt-0 w-full lg:w-auto [ lg:hidden order-last ]" />
    <CategoryMegaMenu class="mt-8" />
    <DialogAcCalculator v-model="showShoppingGuide" />
  </header>
</template>

<script setup lang="ts">
import { BottomSheetType, BottomSheetTransitions } from '@robusta/headless-commerce-elements/common';
const { isLoggedIn } = useAuth();
const { count } = useCartAttributes();
const { emit } = useEventBus();
const showShoppingGuide = ref();
const { toggleDefinedAlias } = useBottomSheet();
const { t } = useI18n({
  useScope: 'local',
});

const { ownerSupport, helpCenter } = useNavLinks();
const { data: pages } = await useAsyncData('smartHomesPages', async () => {
  const { pages, fetchPages } = useFetchSmartHomesPages();
  await fetchPages();
  return pages.value;
});
const { isDevelopment } = useRuntimeConfig().public;
const smartHomes = computed(() => {
  const list = pages.value!.map(item => {
    return {
      url: `/smartHomes/${item.urlKey}`,
      title: item.title,
    };
  });
  return { title: t('smartHomes.title'), links: list };
});
const switchLocalePath = useSwitchLocalePath();

const { t: $t } = useI18n({
  useScope: 'local',
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function openAsideCart() {
  emit('TOGGLE_ASIDE_CART', true);
}

function openSideMenu() {
  // emit('TOGGLE_SIDE_MENU', true);
  toggleDefinedAlias('sideMenu', BottomSheetType.DEFAULT, BottomSheetTransitions.SLIDE_REVERSE);
}
</script>

<style lang="postcss" scoped>
.header-items {
  @apply grid;
  grid-template-columns: 1fr 100px 1fr;

  @screen lg {
    grid-template-columns: 1fr 150px 1fr;
    gap: 30px;
  }

  .header-search {
    grid-column: 1 / span 3;
    @screen lg {
      grid-column: auto;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "loginOrRegister": "Login/Register",
    "greet": "Hello, {name}",
    "contactUs": "Contact us",
    "switchLang": "عربي",
    "logout":  "Logout",
    "notice": "10% OFF & free delivery on online orders",
    "shoppingGuide": "Shopping Guide",
    "forBusiness": "For Business",
    "ownerSupport": {
    "title": "Owner Support",
    "links": {
      "registerAProduct": "Register a Product",
      "bookAnAppointment": "Book an Appointment",
      "warrantyInformation": "Warranty Information",
      "productManuals": "Product Manuals",
      "troubleshooting": "Troubleshooting",
      "contactCustomerSupport": "Contact Customer Support",
      "careers": "Careers",
      "smartHome": "Smart Home"
    }
  },
  "helpCenter": {
    "title": "Help Center",
    "links": {
      "chatSupport": "Chat Support",
      "faqs": "FAQs",
      "contactUs": "Contact Us"
    }
  },
  "smartHomes": {
    "title": "Smart Homes",
    "links": {
      "midea": "Midea",
      "carrier": "Carrier",
    }
  },
  "toFavorite": "Go to favorite page",
  "aboutUs": "About Us"
  },
  "ar": {
    "loginOrRegister": "تسجيل الدخول/ إنشاء حساب",
    "greet": "مرحبا، {name}",
    "contactUs": "تواصل معنا",
    "switchLang": "English",
    "logout":  "تسجيل الخروج",
    "notice": "10٪ خصم على الطلبات الاونلاين",
    "shoppingGuide": "دليل التسوق",
    "forBusiness": "للأقسام التجارية",
    "ownerSupport": {
    "title": "الدعم الخاص بالمُلاك",
    "links": {
      "registerAProduct": "تسجيل المنتج",
      "bookAnAppointment": "حجز موعد",
      "warrantyInformation": "معلومات الضمان",
      "productManuals": "دليل المنتج"
    },
  },
  "helpCenter": {
    "title": "مركز المساعدة",
    "links": {
      "chatSupport": "الدعم عبر الدردشة",
      "faqs": "الأسئلة الشائعة",
      "contactUs": "تواصل معنا"
    }
  },
  "smartHomes": {
    "title": "سمارت هومز",
    "links": {
      "midea": "Midea",
      "carrier": "Carrier",
    }
  },
  "toFavorite": "الذهاب إلى صفحة المفضلة",
  "aboutUs": "عنا"
  }
}
</i18n>

<style lang="postcss" scoped>
@screen md {
  .header-title {
    display: grid;
    grid-template-columns: 1fr;
  }
}
</style>
